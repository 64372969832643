import clsx from "clsx";
import React, { Fragment } from "react";
import "./LandingScreen.css";
import homeSleepTest from "../../../assets/svgs/home-sleep-test.svg";
import renumaPlus from "../../../assets/svgs/renuma-plus.svg";
import sleepEval from "../../../assets/svgs/sleep-eval.svg";
import pinPurple from "../../../assets/svgs/pin-purple.svg";
import pinLightBlue from "../../../assets/svgs/pin-light-blue.svg";
import Section from "../../../components/Section";
import PrimaryButton from "../../../components/PrimaryButton";
import { useHistory } from "react-router";
import { useAuth } from "../../../providers/auth.context";
import { goToPrivatePage } from "../../../utils";

const LandingScreen = () => {
  const history = useHistory();
  const { currentUser } = useAuth();

  const handleGetStarted = () => {
    if (currentUser != null && currentUser.token) {
      goToPrivatePage({
        history,
        url: "/onboarding/survey",
        warningText:
          "You already took the survey, this service can't be done twice.",
      });
      return;
    }
    history.push("/get-an-account");
  };

  const handleSleepApnea101 = () => {
    history.push("/sleep-apnea-101");
  };

  return (
    <Fragment>
      <Section className="bg-mobileHomeHero md:bg-homeHero bg-cover bg-no-repeat bg-bottom md:bg-right pt-32 md:pt-52 pb-80 md:pb-44">
        <div className="max-w-screen-xl m-auto">
          <div className="md:w-5/12 ml-0 md:ml-8">
            <h1
              className="text-4xl font-bold mb-6"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              Sleep Apnea Simplified
            </h1>
            <p
              className="font-body text-base md:text-2xl leading-relaxed mb-10"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              Sleep apnea affects about 10% of people. We can help you figure
              out if you're one of them.
            </p>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <PrimaryButton onClick={handleGetStarted}>
                Begin Sleep Survey
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 text-center py-32">
        <div className="max-w-4xl m-auto">
          <div data-aos="fade-up" data-aos-duration="700">
            <H2 className="mb-6 text-center leading-10">
              Welcome <br className="md:hidden" />
              to Better Sleep
            </H2>
          </div>
          <p
            className="font-body text-base md:text-xl2 leading-6"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            Renuma offers the easiest, most stress-free way to diagnose and
            treat sleep apnea. We will help to monitor your sleep, care for your
            equipment needs, and update your supplies. No doctor’s office visits
            and no insurance hassles.
          </p>
        </div>
      </Section>
      <Section className="bg-white landing-page-how-it-works pt-32">
        <div className="max-w-5xl m-auto flex flex-col">
          <div>
            <div data-aos="fade-up" data-aos-duration="700">
              <H2 className="text-center mb-1">How it Works</H2>
            </div>
            <p
              className="font-body text-center mb-16 md:mb-24 text-serif text-base md:text-xl2"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              A better night's sleep in 3 easy steps
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between mb-28 md:mb-24 space-x-0 md:space-x-8">
            <div
              className="flex-1 flex flex-col items-center mb-16 md:mb-0"
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <img
                alt="sleep-evaluation"
                className="mb-6 md:mb-12"
                src={sleepEval}
              />
              <h3 className="text-xl text-center font-semibold text-primary-text">
                Online Sleep Survey
              </h3>
              <p className="font-body text-base text-center leading-6">
                Take our sleep apnea survey to find out if you are a good
                candidate for a home sleep test.
              </p>
            </div>
            <div
              className="flex-1 flex flex-col items-center mb-16 md:mb-0"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              <img
                alt="sleep-test"
                className="mb-6 md:mb-12"
                src={homeSleepTest}
              />
              <h3 className="text-xl text-center font-semibold text-primary-text">
                Home Sleep Test
              </h3>
              <p className="font-body text-base text-center leading-6">
                Take the home sleep test from the comfort of your home. Includes
                a telemedicine consultation with a physician board certified in sleep medicine to review your results.
              </p>
            </div>
            <div
              className="flex-1 flex flex-col items-center"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <img
                alt="renuma-plus"
                className="mb-6 md:mb-12"
                src={renumaPlus}
              />
              <h3 className="text-xl text-center font-semibold text-primary-text">
                Ongoing Care with Renuma+
              </h3>
              <p className="font-body text-base text-center leading-6">
                Sign up for Renuma+ to start treatment for your sleep apnea.
                Your sleep doctor will work with you to find the right treatment for you among the many available options. 
              </p>
            </div>
          </div>
          <div
            className="flex justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <button
              onClick={() => history.push("/how-it-works")}
              className="border-2 border-renuma-violet-primary text-renuma-violet-primary px-12 py-4 text-lg font-bold rounded-full"
            >
              Learn More
            </button>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-300 text-center py-32">
        <div className="max-w-4xl m-auto md:my-2.5">
          <div data-aos="fade-up" data-aos-duration="700">
            <H2 className="mb-6 text-center">Locations of Service</H2>
          </div>
          <p
            className="font-body text-base md:text-2xl"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            We are currently offering our services to those living in these
            states
          </p>
          <div
            className="flex flex-wrap justify-center mt-2"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Arizona
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Arkansas
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Colorado
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Connecticut
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Delaware
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Florida
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Georgia
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Kentucky
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Maryland
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Minnesota
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Mississippi
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Nevada
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              New Hampshire
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              New Jersey
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              New York
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              North Carolina
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              North Dakota
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Ohio
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Oklahoma
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Pennsylvania
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              South Dakota
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Tennessee
            </div>
            <div className="mx-3 flex items-center font-body text-base md:text-2xl">
              Texas
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-gray-100 py-32">
        <div className="flex flex-col items-center max-w-4xl m-auto">
          <div data-aos="fade-up" data-aos-duration="700">
            <H2 className="text-center mb-4">Sleep Apnea 101</H2>
          </div>
          <h3
            className="text-center text-primary-text font-sans font-semibold text-xl mb-4"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Suspect you have sleep apnea? You're not alone.
          </h3>
          <p
            className="font-body mb-10 text-center text-base md:text-xl2 leading-6"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Sleep apnea affects millions of people – most of whom don’t know
            they have the condition and are unaware of its potential to cause
            harm.
          </p>
          <PrimaryButton
            onClick={handleSleepApnea101}
            data-aos="fade-up"
            data-aos-duration="700"
          >
            Learn More
          </PrimaryButton>
        </div>
      </Section>
    </Fragment>
  );
};
const H2 = ({ children, className }) => (
  <h2 className={clsx("text-3xl font-bold text-primary-text", className)}>
    {children}
  </h2>
);
export default LandingScreen;
