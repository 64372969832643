import React, { useEffect, useState } from "react";
import { Button, Skeleton, Calendar } from "antd";
import { useHistory, useParams } from "react-router";
import Section from "../../../components/Section";
import {
  getProfessional,
  getSchedule,
  saveSchedule,
  updateSchedule,
  cancelSchedule,
} from "../../../APIClient";
import {
  ClockCircleFilled,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
import { USER_APPOINTMENT_DATA, USER_PROFILE, getSlot } from "../../../utils";
import { actionButton } from "aws-amplify";

const Telemedicine = (props) => {
  const { date } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  const [day, setDay] = useState(moment().format("DD"));
  const [hour, setHour] = useState("");
  const [professionals, setProfessionals] = useState(null);
  const [indexPrf, setIndexPrf] = useState(0);
  const [selectedPrf, setSelectedPrf] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [slots, setSlots] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [editSlot, setEditSlot] = useState(null);
  const [storedSlot, setStoredSlot] = useState(null);
  const [isFree, setIsFree] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const history = useHistory();
  const [changeMonth, setChangeMonth] = useState(false);

  useEffect(() => {
    if (date) {
      if (date === "free") {
        setIsFree(true);
        const userProfile = JSON.parse(localStorage.getItem(USER_PROFILE));
      } else if (date.indexOf("T") > -1) {
        setEditDate(date);
      } else {
        const _date = date.split("-");
        setYear(_date[0]);
        setMonth(_date[1]);
        setDay(_date[2]);
      }
    }
    _getProfessionals();
  }, []);

  useEffect(() => {
    if (selectedPrf) {
      if (changeMonth) {
        _getSchedule();
      } else {
        const appointmentData = JSON.parse(
          localStorage.getItem(USER_APPOINTMENT_DATA)
        );

        if (
          appointmentData !== null &&
          appointmentData.selected_datetime !== undefined
        ) {
          const _dateTime = appointmentData.selected_datetime.split("T");
          const _date = _dateTime[0].split("-");
          const _time = _dateTime[1].split("-");
          const _slot = {
            date_time: appointmentData.utc_datetime,
            hour: _time[0],
            taken: false,
          };
          setYear(_date[0]);
          setMonth(_date[1]);
          setDay(_date[2]);

          setStoredSlot(_slot);
          handleSelectSlot(_slot);
        }
        _getSchedule();
      }
    }
  }, [selectedPrf, month, changeMonth]);

  useEffect(() => {
    if (schedule !== null && day) {
      _searchDaySlots();
      setLoading(false);
    }
  }, [schedule, day]);

  const setEditDate = (date) => {
    const _params = date.split("_");
    const _datetime = _params[0].split("T");
    const _date = _datetime[0].split("-");
    setYear(_date[0]);
    setMonth(_date[1]);
    setDay(_date[2]);
    setIsEditMode(true);
    setScheduledDate(_params[0]);

    setAppointmentId(_params[1]);
    const _slot = {
      date_time: _params[0],
      hour: moment(_params[0]).tz("America/New_York").format("hh:mm:ss"),
      taken: true,
    };
    setEditSlot(_slot);
    handleSelectSlot(_slot);
  };

  const _getProfessionals = async () => {
    setLoading(true);
    try {
      const res = await getProfessional();
      setProfessionals(res.Items);
      setSelectedPrf(res.Items[indexPrf]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const _getSchedule = async () => {
    setLoading(true);
    let newDay = moment().format("DD");
    if (month.toString() !== moment().format("MM")) {
      newDay = null;
    }
    try {
      const res = await getSchedule(year, month, newDay, selectedPrf.email);
      setSchedule(res.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const _searchDaySlots = () => {
    const filtered = _.filter(schedule, { day: parseInt(day) });
    let activedHours = filtered[0].schedule;

    if (moment(`${year}-${month}-${day}`).isSame(moment(), "day")) {
      const hours = Object.assign(activedHours);
      const now = moment().add(1, "hour");
      const actived = hours.filter((i) => {
        const slot = moment(`${year}-${month}-${day} ${i.hour}`);
        return slot.isAfter(now, "hour minute");
      });
      activedHours = actived;
    }
    if (moment(`${year}-${month}-${day}`).isSameOrAfter(moment(), "day")) {
      setSlots(activedHours);
    } else {
      setSlots(null);
    }
  };

  const handleSelectDate = (e) => {
    setDay(e.format("DD"));
    setHour("");
    setSelectedSlot(null);
  };

  const handleSelectSlot = (slot) => {
    setHour(slot.hour);
    setSelectedSlot(slot);
  };

  const handlePrevMonth = () => {
    if (parseInt(month) === parseInt(moment().format("M"))) {
      return;
    }
    setChangeMonth(true);
    setSchedule(null);
    setSlots(null);
    if (parseInt(month) === 1) {
      setMonth("12");
      setYear((y) => parseInt(y) - 1);
    } else {
      setMonth((m) => {
        const p = parseInt(m) - 1;
        return p < 10 ? `0${p}` : p;
      });
    }
  };
  const handleNextMonth = () => {
    setChangeMonth(true);
    setSchedule(null);
    setSlots(null);
    if (parseInt(month) === 12) {
      setMonth("01");
      setYear((y) => parseInt(y) + 1);
    } else {
      setMonth((m) => {
        const p = parseInt(m) + 1;
        return p < 10 ? `0${p}` : p;
      });
    }
  };

  const isSelectedMonth = (date) => {
    return parseInt(date.format("MM")) === parseInt(month);
  };

  const isDisabled = (date) => {
    if (schedule && isSelectedMonth(date)) {
      const filtered = _.filter(schedule, { day: parseInt(date.format("DD")) });
      return filtered[0].schedule.length === 0;
    }
    return true;
  };

  const isStoredSlot = (hour) => {
    return storedSlot.hour === hour;
  };

  const isScheduledSlot = (hour) => {
    return selectedSlot.hour === hour;
  };

  // isFree = false
  // o cuando se edita desde el checkout "/appointment_checkout"
  // se guardan los datos en localstorage y se redirige
  // al usuario al checkout
  const _confirmSchedule = () => {
    setSaving(true);
    setStoredSlot(null);

    const data = {
      professional: selectedPrf.email,
      date_time: selectedSlot.date_time,
      kareo_id: selectedPrf.kareo_id,
    };
    localStorage.setItem(
      USER_APPOINTMENT_DATA,
      JSON.stringify({
        professional: selectedPrf,
        selected_datetime: moment(
          `${year}-${month}-${day} ${hour}`,
          "YYYY-MM-DD hh:mm a"
        ).format(),
        utc_datetime: selectedSlot.date_time,
      })
    );

    setSaving(false);

    if (isFree) {
      history.push("/onboarding/appointment_confirmation/free");
    } else if (isEditMode) {
      history.goBack();
    } else {
      history.push("/onboarding/appointment_checkout");
    }
    // _getSchedule();
  };

  // isFree = true se guarda el schedule
  const _saveSchedule = async () => {
    setSaving(true);
    setStoredSlot(null);
    const data = {
      professional: selectedPrf.email,
      date_time: selectedSlot.date_time,
      kareo_id: selectedPrf.kareo_id,
    };

    try {
      const res = await saveSchedule(data);
      localStorage.setItem(
        USER_APPOINTMENT_DATA,
        JSON.stringify({
          professional: selectedPrf,
          selected_datetime: moment(
            `${year}-${month}-${day} ${hour}`,
            "YYYY-MM-DD hh:mm a"
          ).format(),
          utc_datetime: moment(
            `${year}-${month}-${day} ${hour}`,
            "YYYY-MM-DD hh:mm a"
          )
            .utc()
            .format(),
        })
      );
      if (isFree) {
        history.push("/onboarding/appointment_confirmation/free");
      } else {
        history.push("/onboarding/appointment_checkout");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      _getSchedule();
    }
  };

  const _cancelSchedule = async () => {
    setSaving(true);
    setStoredSlot(null);
    const data = {
      id: appointmentId,
    };

    try {
      const res = await cancelSchedule(data);

      setIsEditMode(false);
      setHour("");
      setSelectedSlot(null);
      _getSchedule();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      _getSchedule();
    }
  };

  const _editSchedule = async () => {
    setSaving(true);
    setStoredSlot(null);
    const data = {
      id: appointmentId,
      professional: selectedPrf.email,
      date_time: selectedSlot.date_time,
    };

    try {
      const res = await updateSchedule(data);
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      // _getSchedule();
    }
  };

  const calendarHeader = ({ value, type, onChange, onTypeChange }) => {
    const current = value.clone();
    return (
      <div className="flex flex-row pb-2 pl-4 pr-2">
        <div className="flex-1 pt-2">{current.format("MMMM YYYY")}</div>
        <div className="flex flex-row justify-between w-20">
          <Button type="text" onClick={handlePrevMonth}>
            <LeftOutlined />
          </Button>
          <Button type="text" onClick={handleNextMonth}>
            <RightOutlined />
          </Button>
        </div>
      </div>
    );
  };

  const dateCellRender = (date) => {
    if (schedule && isSelectedMonth(date)) {
      const selected =
        date.format() === moment(`${year}-${month}-${day}`).format();
      const disabled = isDisabled(date);
      return (
        <div
          className={clsx(
            "rounded-full h-10 w-10 flex justify-center items-center",
            selected
              ? "bg-renuma-violet-primary text-white"
              : "bg-renuma-violet-400",
            disabled && !selected ? "bg-renuma-violet-white" : ""
          )}
        >
          {date.format("DD")}
        </div>
      );
    }
  };

  const actionButton = (item) => {
    if (selectedSlot !== null && selectedSlot.hour === item.hour) {
      if (saving) {
        return (
          <Button
            className="flex-1 h-12 btn-renuma-calendar bg-gray-200 text-red-text text-xs"
            onClick={null}
            disabled={true}
          >
            Please Wait 60 Seconds
          </Button>
        );
      } else {
        // !saving
        // slot guardado en localStorage
        if (storedSlot) {
          // si el slot guardado en localStorage
          // es igual a la hora de este item
          if (isStoredSlot(item.hour)) {
            return (
              <Button
                className={
                  "flex-1 h-12 btn-renuma-calendar bg-gray-200 text-white"
                }
                onClick={null}
                disabled={true}
              >
                Selected
              </Button>
            );
          } else {
            // !isStoredSlot()
            if (!isEditMode) {
              return (
                <Button
                  className={
                    "flex-1 h-12 btn-renuma-calendar bg-renuma-violet-700 text-white"
                  }
                  onClick={() =>
                    storedSlot != null ? _confirmSchedule() : _saveSchedule()
                  }
                >
                  Change
                </Button>
              );
            }
          }
        } else {
          // !storedSlot
          if (isEditMode) {
            if (isScheduledSlot(item.hour) && editSlot.hour === item.hour) {
              return (
                <Button
                  className={
                    "flex-1 h-12 btn-renuma-calendar bg-renuma-violet-700 text-white"
                  }
                  onClick={() => _cancelSchedule()}
                >
                  Cancel
                </Button>
              );
            } else {
              return (
                <Button
                  className={
                    "flex-1 h-12 btn-renuma-calendar bg-renuma-violet-700 text-white"
                  }
                  onClick={() => _editSchedule()}
                >
                  Change
                </Button>
              );
            }
          } else {
            return (
              <Button
                className="flex-1 h-12 btn-renuma-calendar bg-renuma-violet-700 text-white"
                onClick={() =>{
                    if(!isFree){
                      _confirmSchedule()
                    } else {
                      _saveSchedule()
                    }
                  }
                }
              >
                Confirm
              </Button>
            );
          }
        }
      }
    }
    return null;
  };

  return (
    <Section>
      <div className="max-w-5xl w-full mx-auto flex flex-col md:flex-row shadow-lg py-6">
        <div className="py-6 md:py-0 px-6 mb-3 md:mb-0 w-full">
          {selectedPrf && !loading && (
            <>
              <div className="flex flex-row justify-center md:justify-start">
                <img
                  src={selectedPrf.avatar}
                  alt=""
                  className="h-14 w-14 mb-2.5"
                />
              </div>
              <p className="text-base leading-normal mb-2 text-center md:text-left">
                {selectedPrf.first_name} {selectedPrf.last_name}
                {", "}
                {selectedPrf.title}
              </p>
              <p className="mb-2 text-center md:text-left">
                <strong className="text-base leading-normal">
                  Telemedicine consultation
                </strong>
              </p>
              <p className="text-lg leading-normal text-grey-200 flex flex-row items-center mt-10 md:mt-0">
                <ClockCircleFilled className=" text-grey-200 mr-2" /> 15 min
              </p>
              <p className="font-body text-xs leading-normal">
                A sleep doctor will discuss treatment options with you and
                develop a plan and answer all your questions.
              </p>
            </>
          )}
          <div>
            <Skeleton
              active
              loading={loading}
              shape="circle"
              avatar
              paragraph={{ rows: 0 }}
            />
            <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
          </div>
        </div>

        <div className="flex flex-col max-w-sm w-full px-6">
          <div className="h-14 mb-0.5">
            <p className="text-base leading-normal mb-0 text-center md:text-left">
              Select a Date & Time
            </p>
          </div>
          <div className="flex-1">
            <Calendar
              fullscreen={false}
              defaultValue={moment(`${year}-${month}-${day}`)}
              value={moment(`${year}-${month}-${day}`)}
              dateFullCellRender={dateCellRender}
              disabledDate={isDisabled}
              headerRender={calendarHeader}
              onSelect={handleSelectDate}
            />
          </div>
        </div>

        <div className="max-w-xs w-full pl-6">
          <div className="h-14 mb-2.5"></div>
          <p>Please allow up to a minute for your chosen date and time to be processed. <b>Do not hit the back button.</b></p>
          <p className="text-sm leading-normal">
            {moment(`${year}-${month}-${day}`).format("dddd, MMMM DD")} (ET)
          </p>
          <div className="space-y-2 pr-6 h-96 overflow-y-auto">
            {(slots === null || slots.length === 0) && (
              <div className="text-xs leading-normal">
                There are no times availables
              </div>
            )}
            {slots !== null &&
              slots.map((item, k) => {
                return (
                  <div key={k} className="flex flex-row space-x-2">
                    <Button
                      className={clsx(
                        "flex-1 h-12 btn-renuma-calendar",
                        selectedSlot !== null && selectedSlot.hour === item.hour
                          ? "bg-secondary-text text-white"
                          : ""
                      )}
                      onClick={() => handleSelectSlot(item)}
                      disabled={item.taken}
                    >
                      {item.hour}
                    </Button>

                    {actionButton(item)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Telemedicine;
